import FPDManager from "./fpd-manager"
import { getFilename, getPath } from '../lib/helpers/utils.js'
import { get } from 'lodash'
import tinycolor from 'tinycolor2'
/**
 * Example 3D model URL
 * https://fpd-shopify-dev.s3.eu-central-1.amazonaws.com/models/t-shirt-male/
 */
export default class Preview3dOperator {
  static extral3DPreviewId = 'fpd-3d-preview-placeholder'

  constructor(productPage) {
    this.productPage = productPage
    this.optionsManager = productPage.optionsManager
    this.preview3dClass = FPDManager.getModuleClass('FPD3DPreview')
    this.preview3dElement = null
    this.threeDModelsId = this.getThreeDModelId()
    this.modelConfig = null
    
    this.config = {
      placement: 'designer',
      canvasWidth: this.optionsManager.get3DPreviewWidth(),
      canvasHeight: this.optionsManager.get3DPreviewHeight(),
      modelsUrl: `${this.optionsManager.apiUrl}/fe/v1/three_d_models/${this.threeDModelsId}?shopify_domain=${this.optionsManager.shopifyDomain}`
    }
  }

  initReadyListener() {
    FPDManager.addDesignerReadyCallback(() => {
      this.initEventListener()
    })
  }

  initEventListener() {
    if(this.eventListenerInitialized) return;
  
    FPDManager.instance.addEventListener('productCreate', this.createPreview3D.bind(this));
    FPDManager.instance.addEventListener('modalDesignerOpen', this.createPreview3D.bind(this));
    FPDManager.instance.addEventListener('viewCanvasUpdate', (evt) => {
			const { viewInstance } = evt.detail;
			this.drawImageOnModel(viewInstance);
		})
    FPDManager.instance.addEventListener("elementFillChange", (evt) => {
			const { element } = evt.detail;
			if (FPDManager.instance.productCreated) {
				if (tinycolor(element.fill).isValid() && this.preview3dInstance) {
					this.preview3dInstance.changeChildColor(element.colorLink3DLayer, tinycolor(element.fill).toRgbString());
				}
			}
		});

    //create 3d preview is product is already created, e.g. no elements in fpd product
    if (FPDManager.instance.productCreated) {
			this.createPreview3D();
		}

    document.body.addEventListener('click', (event) => {
      this.resizeRenderWindow(event, FPDManager)
    });
    document.body.addEventListener('touchend', (event) => {
      this.resizeRenderWindow(event, FPDManager)
    });

    this.inferPreviewPlacement()

    this.eventListenerInitialized = true;
  }

  inferPreviewPlacement() {
    if(document.getElementById(Preview3dOperator.extral3DPreviewId)) {
      this.config.placement = 'on_product_page'
    }
  }

  createPreview3D() {
    // remove existing preview
    if (this.preview3dElement) {

			if (this.preview3dInstance) {
				this.preview3dInstance.destroy();
      }

			this.preview3dElement.remove();
      this.preview3dElement = null;
      this.preview3dInstance = null
		}

    if (FPDManager.instance.container.clientWidth <= 0) return;

    // @todo replace with option manager calls
    if(this.threeDModelsId) {
      const modelDir = this.threeDModelsId;
      fetch(this.config.modelsUrl)
				.then(response => response.json())
				.then(modelsResponse => {
          // console.debug("modelsResponse", modelsResponse)
          this.modelConfig = modelsResponse.config;

          this.createContainer()

          if(this.modelConfig) {
            if(this.config.placement == 'designer') {
              this.preview3dElement.style.width = this.config.canvasWidth + 'px';
              this.preview3dElement.style.height = this.config.canvasHeight + 'px';

              FPDManager.instance.mainWrapper.container.appendChild(this.preview3dElement);
            } else {
              // @todo figure out element for preview placeholder
              const placeholder = document.getElementById(Preview3dOperator.extral3DPreviewId);

							if (!placeholder) return;

							placeholder.appendChild(this.preview3dElement);

							this.config.currentCanvasWidth = placeholder.clientWidth;
							this.config.currentCanvasHeight = placeholder.clientHeight;

							this.preview3dElement.style.width = this.config.currentCanvasWidth + 'px';
							this.preview3dElement.style.height = this.config.currentCanvasHeight + 'px';
            }

            if (this.preview3dElement.clientWidth <= 0) return;

            this.preview3dInstance = new this.preview3dClass('fpd-3d-preview', {
							width: this.config.currentCanvasWidth * 2,
							height: this.config.currentCanvasHeight * 2,
							imgPath: getPath(this.modelConfig.environment_map_url) + '/',
							modelPath: getPath(this.modelConfig.model_url) + '/',
							modelFilename: getFilename(this.modelConfig.model_url),
							cameraZ: this.modelConfig.camera_z,
							baseMaterialMetalness: this.modelConfig.base_material_metalness,
							baseMaterialRoughness: this.modelConfig.base_material_roughness,
							modalLoad: () => {
								FPDManager.instance.viewInstances.forEach((viewInstance) => {
									this.drawImageOnModel(viewInstance);
								})
							}
						});
          }
        })
    }
  }

  // this creates a image of the regular canvas and loads it into the 3d model
  drawImageOnModel(viewInstance = null) {
    if(viewInstance && FPDManager.instance.productCreated && this.preview3dInstance && this.preview3dInstance.modalCreated) {
      const options = {
        onlyExportable: this.modelConfig.hasOwnProperty('only_exportable') ? Boolean(this.modelConfig.only_exportable) : true,
        format: 'png'
      }

      const viewIndex = FPDManager.instance.viewInstances.indexOf(viewInstance);

			var padding = this.modelConfig.print_padding ? Number(this.modelConfig.print_padding) : 0;
      viewInstance = FPDManager.instance.currentViewInstance
			if (viewInstance.options.printingBox) {
			    options.left = viewInstance.options.printingBox.left - padding;
			    options.top = viewInstance.options.printingBox.top - padding;
			    options.width = viewInstance.options.printingBox.width + ( padding * 2 );
			    options.height = viewInstance.options.printingBox.height + ( padding * 2 );
			}

      viewInstance.toDataURL((base64Data) => {
        	//loads a base64 image into a specific area in the 3d model by index(0,1,2...)								
			    this.preview3dInstance.loadBase64(base64Data, viewIndex);
      }, options, false)  
    }
  }

  createContainer() {
    this.preview3dElement = document.createElement('div');
		this.preview3dElement.id = 'fpd-3d-preview';
		this.preview3dElement.className = 'fpd-3d-preview-wrapper';

    const loadingDiv = document.createElement('div');
		loadingDiv.className = 'fpd-loading';
		loadingDiv.textContent = 'Loading...';
    this.preview3dElement.appendChild(loadingDiv);

    if(this.config.placement == 'designer') {

			const fullscreenToggle = document.createElement('div');
			fullscreenToggle.className = 'fpd-fullscreen-toggle';

			const fullscreenIcon = document.createElement('span');
			fullscreenIcon.className = 'fpd-icon-fullscreen';
			fullscreenToggle.appendChild(fullscreenIcon);

			const closeFullscreenIcon = document.createElement('span');
			closeFullscreenIcon.className = 'fpd-icon-fullscreen-close';
			fullscreenToggle.appendChild(closeFullscreenIcon);

			this.preview3dElement.appendChild(fullscreenToggle);
		}
  }

  resizeRenderWindow(event, FPDManager) {
    if (event.target.closest('.fpd-3d-preview-wrapper .fpd-fullscreen-toggle')) {
			if(FPDManager.instance && this.preview3dInstance && this.preview3dInstance.modalCreated) {

				let width = this.config.canvasWidth
				let height = this.config.canvasHeight

        this.preview3dElement.classList.toggle('fpd-fullscreen');

				if (this.preview3dElement.classList.contains('fpd-fullscreen')) {
					width = FPDManager.instance.mainWrapper.container.clientWidth;
					height = FPDManager.instance.mainWrapper.container.clientHeight;
				}

				this.preview3dElement.style.width = width + 'px';
				this.preview3dElement.style.height = height + 'px';

				this.preview3dInstance.setSize(width*2, height*2);

				FPDManager.instance.deselectElement();
			}
		}
  }

  getThreeDModelId() {
    return get(this.productPage.fpdProductJSON, 'plugin_options.productsJSON[0][0].options.threeJsPreviewModel', undefined)
  }

  checkElemReady(selector, callback) {
    var elementLoaded = false;
  
    var observer = new MutationObserver(function(mutations) {
      mutations.forEach(function(mutation) {
        if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
          // check class
          if (mutation.target.classList.contains(selector) && elementLoaded === false) {
            elementLoaded = true;
  
            callback(mutation.target);
  
            observer.disconnect();
          }
        }
      });
    });
  
    observer.observe(document.body, { childList: true, subtree: true, attributes: true, attributeFilter: ['class']  });
  }
}